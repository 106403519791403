<template>
  <b-card
    no-body
    :class="{ open: visible }"
    :style="resolveBorderColorByAirline(item.airline)"
    style="border-radius: 0 8px 8px 0;"
  >
    <b-card-header
      :class="`cursor-pointer ${visible ? 'bg-light-warning' : 'collapsed'}`"
      :aria-expanded="visible"
      :aria-controls="item.id"
      role="tab"
      class="p-0"
      :data-toggle="`collapseflight-${item.id}`"
      @click="updateVisible(!visible)"
    >
      <slot name="header">
        <div class="w-100">
          <b-row
            :class="`d-flex-between flex-md-nowrap ${(appBreakPoint !== 'xl') ? 'font-small-3': ''}`"
            style="height: 40px;"
            no-gutters
          >
            <!-- ANCHOR - 1 Logo -->
            <b-col
              class="d-flex-center"
              cols="2"
              md="1"
            >
              <div class="ml-50">
                <IAmLogoAirline
                  :airline="item.segments[0].operating === 'BL' ? item.segments[0].operating : (item.airline.split(' | ')[0] || item.operator)"
                  :height="40"
                />
              </div>
            </b-col>

            <!-- ANCHOR - 2 Số hiệu -->
            <b-col
              class="d-flex flex-column justify-content-center align-items-center"
              cols="1"
            >
              <div
                v-b-tooltip.hover.html.v-primary
                class="font-weight-bolder align-center"
                :title="item.segments.map(item => `${resolveAirlineFlightNumber(item.airline, item.flightNumber)}`).join(' | ')"
              >
                <span
                  class="text-airline text-nowrap fw-700"
                  :class="isMobileView ? '' : 'font-medium-2'"
                >
                  {{ `${resolveAirlineFlightNumber(item.segments[0].airline, item.segments[0].flightNumber)}` }}
                </span>
              </div>

              <!-- ANCHOR - Tên máy bay - Mobile -->
              <div
                v-if="isMobileView && getShowAircraft"
                class="text-center"
              >
                <span
                  v-b-tooltip.hover.html.v-primary
                  class="text-nowrap text-body fw-700"
                  :title="getAirCraftByIata(item.segments[0].airCraft) || item.segments[0].airCraft"
                >
                  {{ convertShortenAircraft(item.segments[0].airCraft) }}
                </span>
              </div>
            </b-col>

            <!-- ANCHOR - 3 Thời gian bắt đầu - kết thúc -->
            <b-col
              md="2"
              class="d-none d-md-block text-black text-center"
            >
              <span
                v-b-tooltip.hover.html.v-primary.top.window
                :class="['xs'].includes(appBreakPoint) ? 'font-small-1' : 'font-medium-1'"
                :title="`${$t('flight.totalDuration')}: ${toHoursAndMinutes(item.duration)}`"
              >
                {{ `${convertISODateTime(item.departure.at, item.departure.timezone).time} - ${convertISODateTime(item.arrival.at, item.arrival.timezone).time}` }}
              </span>
            </b-col>

            <!-- ANCHOR - 4 Điểm bắt đầu - điểm dừng - điểm kết thúc -->
            <b-col
              cols="5"
              md="3"
              class="text-center"
              :class="isMobileView ? '' : 'font-medium-2'"
            >
              <div>
                <span
                  v-for="(point, pointIndex) in tripArray"
                  :key="pointIndex"
                >
                  <span
                    v-b-tooltip.hover.html.v-primary.top.window
                    :class="pointIndex !== 0 && pointIndex !== tripArray.length - 1 ? `font-weight-light ${isMobileView ? 'font-small-2' : 'font-medium-1'}` : ''"
                    :title="isMobileView ? '' : `<div>${pointIndex !== 0 && pointIndex !== tripArray.length - 1 ? 'Điểm dừng:' : ''} ${point.name}</div>
                    ${point.transitTime ? `<div>${$t('ticket.flightsInfo.transitTime')}: ${point.transitTime}</div>` : ''}`"
                  >
                    {{ point.iataCode }}
                  </span>
                  <span v-if="pointIndex !== tripArray.length - 1"> - </span>
                </span>
              </div>
              <div
                v-if="isMobileView"
                :class="isMobileView ? 'font-weight-bold': ''"
              >
                {{ `${convertISODateTime(item.departure.at, item.departure.timezone).time} - ${convertISODateTime(item.arrival.at, item.arrival.timezone).time}` }}
              </div>
            </b-col>

            <!-- ANCHOR - 5 Tên máy bay -->
            <b-col
              v-if="getShowAircraft"
              class="d-none d-md-inline"
              cols="1"
            >
              <span
                v-b-tooltip.hover.html.v-primary
                class="text-nowrap font-weight-bold"
                :class="isMobileView ? '' : 'font-medium-2'"
                :title="getAirCraftByIata(item.segments[0].airCraft) || item.segments[0].airCraft"
              >
                {{ convertShortenAircraft(item.segments[0].airCraft) }}
              </span>
            </b-col>

            <!-- ANCHOR - 6 Hạng vé -->
            <b-col
              v-if="getShowBookingClass"
              cols="1"
              class="text-warning font-weight-bold text-left d-none d-md-block text-truncate"
              :class="isMobileView ? '' : 'font-medium-2'"
            >
              <div
                v-b-tooltip.hover.html.v-primary
                class="d-flex-center"
                :title="`Hạng vé: ${item.bookingClass}, Chỗ ngồi: ${item.seatAvailable }`"
              >
                <span class="text-truncate">{{ item.bookingClass }}</span>
                <span>({{ item.seatAvailable }})</span>
              </div>
            </b-col>
            <!--<div v-if="getMinFareOptions(item.fareOptions).luggage !== null">
              <b-img
                v-if="getMinFareOptions(item.fareOptions).luggage"
                style="height: 24px;"
                :src="require(`@icons/luggage.svg`)"
              />
              <b-img
                v-else
                style="height: 24px;"
                :src="require(`@icons/no-luggage.svg`)"
              />
            </div>-->

            <!-- ANCHOR - 7 Giá vé -->
            <!-- <b-col
              class="d-flex flex-column align-items-end justify-content-center"
              cols="2"
            >
              <div v-if="!item.fareOptions">
                <span
                  id="flight.full"
                  class="text-danger"
                >{{ $t('flight.full') }}</span>
              </div>
              <div v-else>
                <div
                  v-if="getShowPrice !== 'HIDE_FARE'"
                  :class="journeySelectedId && (journeySelectedId === item.id) ? 'text-success' : 'text-warning'"
                >
                  <div
                    :class="`text-danger fw-600 d-flex-center
                    ${isMobileView ? ' font-small-4' : 'font-medium-2'}`"
                  >
                    {{ formatCurrency(item.fareOptions.totalAdultModified) }}
                  </div>
                </div>

                <div class="d-flex-center">
                  <del
                    v-if="item.fareOptions.discountAdult && getShowPrice !== 'HIDE_FARE'"
                    class="text-secondary font-weight-bolder position-relative"
                    :class="isMobileView ? 'font-small-2' : 'font-small-4'"
                  >
                    <span>{{ formatCurrency(item.fareOptions.totalAdultModified + item.fareOptions.discountAdult) }}</span>
                  </del>
                </div>
              </div>
            </b-col> -->

            <!-- ANCHOR - 8 Checkbox Chọn nhanh -->
            <b-col
              cols="1"
              :class="isMobileView ? 'text-center' : ''"
              class="d-flex-between pl-1 pr-50"
            >
              <template>
                <BButton
                  variant="flat-warning"
                  class="rounded-circle button-remove-focus"
                  style="padding: 1px"
                  @click.stop.prevent="handleChooseSegment(item)"
                >
                  <feather-icon
                    v-if="isChecked"
                    icon="CheckCircleIcon"
                    :size="['xs'].includes(appBreakPoint) ? '22' : '28'"
                    class="text-warning p-25"
                  />
                  <feather-icon
                    v-else
                    icon="CircleIcon"
                    :size="['xs'].includes(appBreakPoint) ? '22' : '28'"
                    class="p-25 text-secondary"
                  />
                </BButton>
              </template>

              <feather-icon
                :class="{ 'rotate-180': !visible, 'rotate-transition': true }"
                icon="ChevronUpIcon"
                size="16"
              />
            </b-col>
          </b-row>
          <!-- <pre>{{ item.fareOptions }}</pre> -->
        </div>
      </slot>
    </b-card-header>

    <b-collapse
      :id="item.id"
      v-model="visible"
      role="tabpanel"
    >
      <BookingDetailTable
        :trip="item"
      />
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCollapse,
  BRow,
  BCol,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

import store from '@/store'

import {
  convertISODateTime, toHoursAndMinutes, resolveAirlineFlightNumber,
  //  formatCurrency,
} from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BCard,
    BCardHeader,
    BCollapse,
    BRow,
    BCol,
    BButton,
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
    BookingDetailTable: () => import('./BookingDetailTable.vue'),
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
    tripId: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      visible: false,
      convertISODateTime,
      toHoursAndMinutes,
    }
  },
  computed: {
    getShowBookingClass() {
      return this.$store.getters['app-flight-v2/getShowBookingClass']
    },
    getShowAircraft() {
      return this.$store.getters['app-flight-v2/getShowAircraft']
    },
  },

  created() {
    this.visible = this.isVisible
  },

  methods: {
    updateVisible(val = true) {
      this.visible = val
    },
    getAirCraftByIata(code) {
      return this.$store.getters['globalConfig/getAirCraftByIata'](code)
    },
    resolveBorderColorByAirline(airline) {
      let textStyle = 'font-weight: 700;'
      if (['VJ', 'VZ'].includes(airline)) textStyle += 'border-left: 5px solid #EC2029 !important;'
      else if (airline === 'VU') textStyle += 'border-left: 5px solid #FFC80B !important;'
      else if (airline === 'VN') textStyle += 'border-left: 5px solid #166987 !important'
      else if (airline === 'QH') textStyle += 'border-left: 5px solid #64AC54 !important'
      else textStyle += 'border-left: 5px solid #000 !important'
      return textStyle
    },
  },

  setup(props) {
    const {
      convertShortenAircraft,
      getAirportByAirportCode,
    } = useBookingHandle()
    const getShowPrice = computed(() => store.getters['app-flight-v2/getShowPrice'])

    function getTripNameBySegments(item) {
      const departure = item.departure.IATACode
      const arrival = item.arrival.IATACode
      const stopPoints = item.stopPoint ? item.stopPoint.split(' | ') : []
      const segments = [departure, ...stopPoints, arrival]
      const tripArr = segments.map((pointItem, pointIndex) => {
        let changeAirport = ''
        if (pointIndex !== 0 && pointIndex !== (segments.length - 1)) {
          const index = item?.segments.findIndex(seg => seg?.arrival.IATACode === pointItem)
          const nextArrival = item?.segments[index + 1]?.departure.IATACode
          if (nextArrival !== pointItem) {
            changeAirport = nextArrival
          }
        }
        const res = {
          iataCode: `${pointItem}${changeAirport ? ` (${changeAirport})` : ''}`,
          name: getAirportByAirportCode(pointItem)?.name,
          transitTime: item?.segments.find(seg => seg?.arrival.IATACode === pointItem)?.transitTime,
        }
        return res
      })
      return tripArr
    }

    const tripArray = computed(() => getTripNameBySegments(props.item))

    function handleChooseSegment(journey) {
      store.dispatch('app-flight-v2/selectCombinationSelectedJourney', { tripId: props.tripId, journey })
    }

    return {
      getTripNameBySegments,
      convertShortenAircraft,
      tripArray,
      getShowPrice,
      // formatCurrency,
      handleChooseSegment,
      resolveAirlineFlightNumber,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
