var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    class: {
      open: _vm.visible
    },
    staticStyle: {
      "border-radius": "0 8px 8px 0"
    },
    style: _vm.resolveBorderColorByAirline(_vm.item.airline),
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "p-0",
    class: "cursor-pointer ".concat(_vm.visible ? 'bg-light-warning' : 'collapsed'),
    attrs: {
      "aria-expanded": _vm.visible,
      "aria-controls": _vm.item.id,
      "role": "tab",
      "data-toggle": "collapseflight-".concat(_vm.item.id)
    },
    on: {
      "click": function click($event) {
        return _vm.updateVisible(!_vm.visible);
      }
    }
  }, [_vm._t("header", [_c('div', {
    staticClass: "w-100"
  }, [_c('b-row', {
    class: "d-flex-between flex-md-nowrap ".concat(_vm.appBreakPoint !== 'xl' ? 'font-small-3' : ''),
    staticStyle: {
      "height": "40px"
    },
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "d-flex-center",
    attrs: {
      "cols": "2",
      "md": "1"
    }
  }, [_c('div', {
    staticClass: "ml-50"
  }, [_c('IAmLogoAirline', {
    attrs: {
      "airline": _vm.item.segments[0].operating === 'BL' ? _vm.item.segments[0].operating : _vm.item.airline.split(' | ')[0] || _vm.item.operator,
      "height": 40
    }
  })], 1)]), _c('b-col', {
    staticClass: "d-flex flex-column justify-content-center align-items-center",
    attrs: {
      "cols": "1"
    }
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html.v-primary",
      modifiers: {
        "hover": true,
        "html": true,
        "v-primary": true
      }
    }],
    staticClass: "font-weight-bolder align-center",
    attrs: {
      "title": _vm.item.segments.map(function (item) {
        return "".concat(_vm.resolveAirlineFlightNumber(item.airline, item.flightNumber));
      }).join(' | ')
    }
  }, [_c('span', {
    staticClass: "text-airline text-nowrap fw-700",
    class: _vm.isMobileView ? '' : 'font-medium-2'
  }, [_vm._v(" " + _vm._s("".concat(_vm.resolveAirlineFlightNumber(_vm.item.segments[0].airline, _vm.item.segments[0].flightNumber))) + " ")])]), _vm.isMobileView && _vm.getShowAircraft ? _c('div', {
    staticClass: "text-center"
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html.v-primary",
      modifiers: {
        "hover": true,
        "html": true,
        "v-primary": true
      }
    }],
    staticClass: "text-nowrap text-body fw-700",
    attrs: {
      "title": _vm.getAirCraftByIata(_vm.item.segments[0].airCraft) || _vm.item.segments[0].airCraft
    }
  }, [_vm._v(" " + _vm._s(_vm.convertShortenAircraft(_vm.item.segments[0].airCraft)) + " ")])]) : _vm._e()]), _c('b-col', {
    staticClass: "d-none d-md-block text-black text-center",
    attrs: {
      "md": "2"
    }
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html.v-primary.top.window",
      modifiers: {
        "hover": true,
        "html": true,
        "v-primary": true,
        "top": true,
        "window": true
      }
    }],
    class: ['xs'].includes(_vm.appBreakPoint) ? 'font-small-1' : 'font-medium-1',
    attrs: {
      "title": "".concat(_vm.$t('flight.totalDuration'), ": ").concat(_vm.toHoursAndMinutes(_vm.item.duration))
    }
  }, [_vm._v(" " + _vm._s("".concat(_vm.convertISODateTime(_vm.item.departure.at, _vm.item.departure.timezone).time, " - ").concat(_vm.convertISODateTime(_vm.item.arrival.at, _vm.item.arrival.timezone).time)) + " ")])]), _c('b-col', {
    staticClass: "text-center",
    class: _vm.isMobileView ? '' : 'font-medium-2',
    attrs: {
      "cols": "5",
      "md": "3"
    }
  }, [_c('div', _vm._l(_vm.tripArray, function (point, pointIndex) {
    return _c('span', {
      key: pointIndex
    }, [_c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.html.v-primary.top.window",
        modifiers: {
          "hover": true,
          "html": true,
          "v-primary": true,
          "top": true,
          "window": true
        }
      }],
      class: pointIndex !== 0 && pointIndex !== _vm.tripArray.length - 1 ? "font-weight-light ".concat(_vm.isMobileView ? 'font-small-2' : 'font-medium-1') : '',
      attrs: {
        "title": _vm.isMobileView ? '' : "<div>".concat(pointIndex !== 0 && pointIndex !== _vm.tripArray.length - 1 ? 'Điểm dừng:' : '', " ").concat(point.name, "</div>\n                  ").concat(point.transitTime ? "<div>".concat(_vm.$t('ticket.flightsInfo.transitTime'), ": ").concat(point.transitTime, "</div>") : '')
      }
    }, [_vm._v(" " + _vm._s(point.iataCode) + " ")]), pointIndex !== _vm.tripArray.length - 1 ? _c('span', [_vm._v(" - ")]) : _vm._e()]);
  }), 0), _vm.isMobileView ? _c('div', {
    class: _vm.isMobileView ? 'font-weight-bold' : ''
  }, [_vm._v(" " + _vm._s("".concat(_vm.convertISODateTime(_vm.item.departure.at, _vm.item.departure.timezone).time, " - ").concat(_vm.convertISODateTime(_vm.item.arrival.at, _vm.item.arrival.timezone).time)) + " ")]) : _vm._e()]), _vm.getShowAircraft ? _c('b-col', {
    staticClass: "d-none d-md-inline",
    attrs: {
      "cols": "1"
    }
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html.v-primary",
      modifiers: {
        "hover": true,
        "html": true,
        "v-primary": true
      }
    }],
    staticClass: "text-nowrap font-weight-bold",
    class: _vm.isMobileView ? '' : 'font-medium-2',
    attrs: {
      "title": _vm.getAirCraftByIata(_vm.item.segments[0].airCraft) || _vm.item.segments[0].airCraft
    }
  }, [_vm._v(" " + _vm._s(_vm.convertShortenAircraft(_vm.item.segments[0].airCraft)) + " ")])]) : _vm._e(), _vm.getShowBookingClass ? _c('b-col', {
    staticClass: "text-warning font-weight-bold text-left d-none d-md-block text-truncate",
    class: _vm.isMobileView ? '' : 'font-medium-2',
    attrs: {
      "cols": "1"
    }
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html.v-primary",
      modifiers: {
        "hover": true,
        "html": true,
        "v-primary": true
      }
    }],
    staticClass: "d-flex-center",
    attrs: {
      "title": "H\u1EA1ng v\xE9: ".concat(_vm.item.bookingClass, ", Ch\u1ED7 ng\u1ED3i: ").concat(_vm.item.seatAvailable)
    }
  }, [_c('span', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.item.bookingClass))]), _c('span', [_vm._v("(" + _vm._s(_vm.item.seatAvailable) + ")")])])]) : _vm._e(), _c('b-col', {
    staticClass: "d-flex-between pl-1 pr-50",
    class: _vm.isMobileView ? 'text-center' : '',
    attrs: {
      "cols": "1"
    }
  }, [[_c('BButton', {
    staticClass: "rounded-circle button-remove-focus",
    staticStyle: {
      "padding": "1px"
    },
    attrs: {
      "variant": "flat-warning"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.handleChooseSegment(_vm.item);
      }
    }
  }, [_vm.isChecked ? _c('feather-icon', {
    staticClass: "text-warning p-25",
    attrs: {
      "icon": "CheckCircleIcon",
      "size": ['xs'].includes(_vm.appBreakPoint) ? '22' : '28'
    }
  }) : _c('feather-icon', {
    staticClass: "p-25 text-secondary",
    attrs: {
      "icon": "CircleIcon",
      "size": ['xs'].includes(_vm.appBreakPoint) ? '22' : '28'
    }
  })], 1)], _c('feather-icon', {
    class: {
      'rotate-180': !_vm.visible,
      'rotate-transition': true
    },
    attrs: {
      "icon": "ChevronUpIcon",
      "size": "16"
    }
  })], 2)], 1)], 1)])], 2), _c('b-collapse', {
    attrs: {
      "id": _vm.item.id,
      "role": "tabpanel"
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('BookingDetailTable', {
    attrs: {
      "trip": _vm.item
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }